import React from 'react'
import Helmet from 'react-helmet'

import "../assets/css/base.css"
import "../assets/css/grid.css"
import "../assets/css/theme.css"

import Navigation from '../components/navigation.js'
import Footer from '../components/footer.js'

const Layout = ({children}) => {
  return (
    <main>
      <Helmet htmlAttributes={{lang: 'en'}} />
      <Navigation />
      
      {children}

      <Footer />

    </main>
  )
}

export default Layout
