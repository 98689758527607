import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <div className="wrap container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <p className="muted">© Billy Dawson 2022</p>
          </div>
          <div className="col-xs-12 col-md-offset-4 col-md-4">
            <p className="muted"><a href="/policies/">Policies</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
