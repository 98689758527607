import React from 'react';

const Contact = ({colour}) => {
  return (
    <div id="contact" className={"section contact " + colour}>
      <div className="wrap container">
        <div className="row">
          <div className="col-xs-12 col-md-offset-4 col-md-5">
            <p className="h3">Contact</p>
            <p>If you need a hand with a development project, do send me an email at the address below and I’ll get back to you as soon as I can.</p>
            <p><a href="mailto:hello@billydawson.co.uk">hello@billydawson.co.uk</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
