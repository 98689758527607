import React, { useState } from "react";

const Navigation = () => {

  const [setMenu, setMenuState] = useState("");

  function toggleMenu() {
    setMenuState(setMenu === "" ? "open" : "");
  }

  return (
    <div className="navigation">
      <div className="wrap container">
        <div className="row">
          <div className="col-xs-6 col-md-4">
            <p className="logo"><a href="/">Billy Dawson</a></p>
          </div>
          <div className="col-xs-6 col-md-8">
            <p className="mobile-toggle" onClick={toggleMenu} role="button">Menu</p>
            <ul className="menu">
              {/*<li><p><a href="/projects/">Projects</a></p></li>*/}
              <li><p><a href="/work/">What I Do</a></p></li>
              <li><p><a href="/about/">About</a></p></li>
              <li><p><a href="#contact">Contact</a></p></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`wrap container mobile-menu ${setMenu}`}>
        <ul className="menu">
          {/*<li><p><a href="/projects/">Projects</a></p></li>*/}
          <li><p><a href="/work/">What I Do</a></p></li>
          <li><p><a href="/about/">About</a></p></li>
          <li><p><a href="#contact">Contact</a></p></li>
        </ul>
      </div>
    </div>
  )
}

export default Navigation
